<template>
  <div>
    <InputField
      ref="input"
      v-model="value"
      type="number"
      :field="field"
      :field-wrapper="true"
    />
  </div>
</template>

<script>
import InputField from "@/components/Tools/FormHelper/Components/Input";
import {
  base,
  eagerValidation
} from "@/components/Tools/FormHelper/Helper/mixins";

export default {
  components: { InputField },
  mixins: [base, eagerValidation],
  methods: {
    validate() {
      return this.$refs.input.validate();
    }
  }
};
</script>
